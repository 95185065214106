<template>
    <!-- tsParticles Repository: https://github.com/matteobruni/tsparticles -->
    <!-- tsParticles Website: https://particles.js.org -->
    <Particles id="tsparticles" :options="options" :particlesInit="particlesInit" />
</template>

<script setup>
import { ref } from 'vue';
import { loadSlim } from 'tsparticles-slim'; // loads tsparticles-slim
// import { loadFull } from 'tsparticles'; // loads tsparticles

const options = ref({
    background: {
        color: "#000", // this sets a background color for the canvas
    },
    fullScreen: {
        enable: true, // enabling this will make the canvas fill the entire screen, it's enabled by default
        zIndex: -1, // this is the z-index value used when the fullScreen is enabled, it's 0 by default
    },
    interactivity: {
        events: {
            onClick: {
                enable: true, // enables the click event, it's disabled by default
                mode: "push", // adds the particles on click
            },
            onHover: {
                enable: true, // enables the mouse hover event, it's disabled by default
                mode: "repulse", // repulse the particles on mouse hover
            },
        },
        modes: {
            push: {
                quantity: 10, // number of particles to add on click
            },
            repulse: {
                distance: 300, // distance of the particles from the cursor
            },
        },
    },
    particles: {
        color: {
            value: "#076fff", // particle color (blue)
        },
        links: {
            enable: false, // disabling particle links
        },
        move: {
            enable: true, // enabling this will make particles move in the canvas
            speed: { min: 1, max: 15 }, // random speed between min/max values
            direction: "top", // particles will move upwards
            outModes: {
                default: "out", // particles will be removed when they move out of canvas
            },
        },
        number: {
            value: 50, // number of particles
        },
        opacity: {
            value: { min: 0.1, max: 0.5 }, // opacity range
        },
        shape: {
            type: 'circle', // shape of particles
        },
        size: {
            value: { min: 3, max: 7 }, // random size between min/max values
        },
        position: {
            y: 100, // start particles from the bottom of the canvas
        },
    },
    detectRetina: true,
});

const particlesInit = async (engine) => {
    await loadSlim(engine);
    // await loadFull(engine); // for this sample the slim version is enough, choose whatever you prefer, slim is smaller in size but doesn't have all the plugins and the mouse trail feature
};
</script>

<style scoped>
/* Add any custom styles you need for your particles */
</style>
