<template>
  <div class="container-fluid">
    <div class="row text-center">
      <div class="col-md-12">
        <div class="slGrey mT10 mBn5">

          <p class="container copyright glasspane border-top pt-5">
            Ackerworx Copyright 2024. All Rights Reserved
          </p>
          <h1 class="mb-0 mt-1 site-logo">
            <!--  <router-link to="/" class="text-black mb-0">
                  <img class="minilogo text-center" src="/frontend/images/logo5.webp">
                </router-link> -->
          </h1>
        </div>
        <a href="https://facebook.com/AckerWorx" class="pl-0 pr-3">
          <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'facebook' }" />
        </a>
        <a href="https://twitter.com/AckerWorx" class="pl-0 pr-3">
          <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'twitter' }" />
        </a>
        <!--   <a href="https://instagram.com/RateOurPolice" class="pl-0 pr-3">
              <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'instagram' }" />
            </a>-->
        <a href="https://linkedin.com/company/AckerWorx" class="pl-0 pr-3">
          <font-awesome-icon :icon="{ prefix: 'fab', iconName: 'linkedin' }" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CopyRight',
};
</script>

<style scoped>
@media (min-width: 1200px) {
    .container{
        max-width: 1140px;
    }
}
a {
	background-color: transparent;
	color: #62a0fa !important;
}
.heartlove {
  color: red;
}
.border-top {
  border-top: 1px solid #65a3ff4f !important;
}
.minilogo {
  width: 100px !important;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
}
.copyright {
  margin-left: auto;
  margin-right: auto;
  color: grey;
}
</style>
